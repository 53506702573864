<template>
    <div>
        <v-container class="my-2">
            <v-layout row wrap px-0 mb-4>
                <v-flex xs9>
                    <v-breadcrumbs class="px-0" :items="$store.getters.language.data.display_text.list_path">
                        <template v-slot:item="{ item }">
                            <v-breadcrumbs-item :to="item.path" :disabled="item.disabled">
                                {{ item.title.toUpperCase() }}
                            </v-breadcrumbs-item>
                        </template>
                    </v-breadcrumbs>
                </v-flex>
                <v-flex xs3>

                </v-flex>
            </v-layout>
            <form @submit.prevent="addDisplayText" autocomplete="off">
                <v-layout row wrap>

                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="display_text.display_text_key" type="text"
                            :label="$store.getters.language.data.display_text.display_text_key" dense class="mx-1"
                            filled outlined required>
                        </v-text-field>
                    </v-flex>

                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="display_text.display_text_en" type="textarea"
                            :label="$store.getters.language.data.display_text.display_text_en" dense class="mx-1" filled
                            outlined required>
                        </v-text-field>
                    </v-flex>

                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="display_text.display_text_ar" type="textarea"
                            :label="$store.getters.language.data.display_text.display_text_ar" dense class="mx-1" filled
                            outlined required>
                        </v-text-field>
                    </v-flex>

                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="display_text.display_text_ku" type="textarea"
                            :label="$store.getters.language.data.display_text.display_text_ku" dense class="mx-1" filled
                            outlined required>
                        </v-text-field>
                    </v-flex>

                    <!-- <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="display_text.display_text_info" type="textarea" :label="$store.getters.language.data.display_text.display_text_info" dense
                            class="mx-1"  filled outlined >
                        </v-text-field>
                    </v-flex> -->
                    <v-flex xs12 lg2 xl2 md2 sm4>
                        <v-btn color="primary" :loading="loading_btn" type="submit">
                            {{$store.getters.language.data.display_text.add_btn}}</v-btn>
                    </v-flex>
                </v-layout>
            </form>
            <v-layout row wrap mt-5>
                <v-flex xs12>
                    <v-card flat>
                        <v-card-title primary-title>
                            
                                <v-text-field v-model="search" append-icon="mdi-magnify" label="Search"
                                    single-line hide-details></v-text-field>
                        </v-card-title>
                        <v-card-text>
                            <v-data-table :loading="loading" :headers="headers" :search="search" :items="rows"
                                class="elevation-0" item-key="display_text_id" items-per-page="-1">
                                <template v-slot:[`item.display_text_id`]="{ item }">
                                    <div>
                                        <v-btn icon :to="'/display_text-list/'+item.display_text_id" color="teal"
                                            class="mx-1">
                                            <v-icon> mdi-pencil-outline </v-icon>
                                        </v-btn>
                                        <v-menu offset-y>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn color="error" icon class="mx-1" v-bind="attrs" v-on="on">
                                                    <v-icon> mdi-delete-outline </v-icon>
                                                </v-btn>
                                            </template>
                                            <v-card>
                                                <v-card-text>
                                                    {{$store.getters.language.data.display_text.delete_question}}
                                                </v-card-text>
                                                <v-card-actions>
                                                    <v-spacer></v-spacer>
                                                    <v-btn text class="elevation-0">
                                                        {{$store.getters.language.data.display_text.cancel_btn}}
                                                    </v-btn>
                                                    <v-btn color="error" class="elevation-0"
                                                        @click="deleteDisplayText(item.display_text_id)">
                                                        {{$store.getters.language.data.display_text.yes_btn}}
                                                    </v-btn>
                                                </v-card-actions>
                                            </v-card>
                                        </v-menu>
                                    </div>
                                </template>
                                <template v-slot:[`item.display_text_key`]="{ item }">
                                    <v-chip outlined color="red">
                                        {{item.display_text_key}}
                                    </v-chip>
                                </template>
                            </v-data-table>
                        </v-card-text>

                    </v-card>
                </v-flex>
            </v-layout>
        </v-container>

        <v-snackbar v-model="snackbar.value" :color="snackbar.color">
            {{snackbar.text}}
        </v-snackbar>
    </div>
</template>
<script>
    import requests from './../../requests/display_text.request.js'
    import * as xlsx from 'xlsx/xlsx.js';
    export default {
        data() {
            return {
                display_text: {},
                search: '',
                loading: true,
                loading_btn: false,

                snackbar: {
                    value: false,
                    text: '',
                    color: ''
                },
                rows: [],

                selected_display_text: {},
                headers: [


                    {
                        text: this.$store.getters.language.data.display_text.display_text_key,
                        align: 'start',
                        sortable: true,
                        value: 'display_text_key',
                    },
                    {
                        text: this.$store.getters.language.data.display_text.display_text_en,
                        align: 'start',
                        sortable: true,
                        value: 'display_text_en',
                    },
                    {
                        text: this.$store.getters.language.data.display_text.display_text_ar,
                        align: 'start',
                        sortable: true,
                        value: 'display_text_ar',
                    },
                    {
                        text: this.$store.getters.language.data.display_text.display_text_ku,
                        align: 'start',
                        sortable: true,
                        value: 'display_text_ku',
                    },
                   {
                        text: this.$store.getters.language.data.actions,
                        align: 'start',
                        sortable: true,
                        value: 'display_text_id',
                    }
                ],
            }
        },
        computed: {
            page() {
                const pageKey = 'DISPLAYTEXT'
                return {}
            }
        },
        mounted() {
            this.readDisplayText();
        },
        methods: {
            exportExcel() {
                const list = this.rows
                let result = []
                for (let i = 0; i < list.length; i++) {
                    const item = list[i];
                    let obj = {
                        display_text_id: item.display_text_id,
                        display_text_key: item.display_text_key,
                        display_text_en: item.display_text_en,
                        display_text_ar: item.display_text_ar,
                        display_text_ku: item.display_text_ku,
                        display_text_info: item.display_text_info,
                    }
                    result.push(obj)
                }
                var f = xlsx.utils.json_to_sheet(result)
                var wb = xlsx.utils.book_new()
                xlsx.utils.book_append_sheet(wb, f, 'sheet')
                xlsx.writeFile(wb, result.length + ' rows of ' + 'DisplayText.xlsx')
            },
            addDisplayText() {
                this.loading_btn = true
                requests.createDisplayText(this.display_text).then(r => {
                        if (r.status == 200 && r.data.status == 'ok') {
                            this.display_text = {}
                            this.rows.push(
                                r.data.new_data
                            )
                            this.snackbar = {
                                value: true,
                                text: 'DisplayText Added',
                                color: 'success'
                            }
                        } else {
                            this.snackbar = {
                                value: true,
                                text: 'Fail to add DisplayText',
                                color: 'error'
                            }
                        }
                    })
                    .finally(() => {
                        this.loading_btn = false
                    })

            },
            deleteDisplayText(display_text_id) {
                requests.deleteDisplayText(display_text_id).then(r => {
                    if (r.status == 200) {
                        this.rows = this.rows.filter(f => {
                            return f.display_text_id != display_text_id
                        })
                        this.snackbar = {
                            value: true,
                            text: 'DisplayText Deleted',
                            color: 'success'
                        }
                    } else {
                        this.snackbar = {
                            value: true,
                            text: 'Delete Faild',
                            color: 'error'
                        }
                    }
                })
            },

            readDisplayText() {
                this.loading = true
                requests.getAllDisplayText().then(r => {
                        if (r.status == 200) {
                            this.rows = r.data.display_text

                            this.loading = false
                        } else {
                            this.snackbar = {
                                value: true,
                                text: 'Fail to read DisplayText',
                                color: 'error'
                            }
                        }
                    })
                    .catch(e => {
                        this.snackbar = {
                            value: true,
                            text: 'Fail to read DisplayText',
                            color: 'error'
                        }
                    })
                    .finally(() => {
                        this.loading = false
                    })
            },
            selectDisplayText(i) {
                this.selected_display_text = i
                this.delete_dialog = true
            },
        },
    }
</script>