
import axios from 'axios';
export default {
    
    async createDisplayText(params)  {
        return await axios.post(`display_text/create.php` , params)
    },
    async getAllDisplayText()  {
        return await axios.get(`display_text/readAll.php`)
    },
    async getOneDisplayText(display_text_id)  {
        return await axios.get(`display_text/readById.php?display_text_id=${display_text_id}`)
    },
    async getDisplayTextByColumn(column , value)  {
        return await axios.get(`display_text/readByColumn.php?column=${column}&value=${value}`)
    },
    async deleteDisplayText(display_text_id)  {
        return await axios.post(`display_text/delete.php?display_text_id=${display_text_id}`)
    },
    async updateDisplayText(display_text_id , params)  {
        return await axios.post(`display_text/update.php?display_text_id=${display_text_id}` , params)
    }
}